
import moment from 'moment';
import { Component, Vue } from 'vue-property-decorator';
import { getIndustryList } from '@/api/industry';
import { getFavoriteItemList, deleteFavoriteItem } from '@/api/favoriteItem';
import Pagination from '@/components/Pagination/index.vue';
import {
  getLat,
  getLng,
  setLat,
  setLng,
} from '@/utils/cookies';

@Component({
  components: {
    Pagination,
  },
})

export default class extends Vue {
  mounted() {
    this.init();
    this.getIndustryList();
  }

  private apiUrl = process.env.VUE_APP_COMMON_API;

  private loading = true;

  private industryList = [];

  private itemList: any[] = [];

  private totalElements = 0;

  private listQuery = {
    page: 0,
    size: 12,
    industryIdx: '0',
    useDate: moment().format('YYYY-MM-DD'),
    startDate: moment().format('YYYY-MM-DD'),
    endDate: moment().add(1, 'days').format('YYYY-MM-DD'),
    adult: 1,
    child: 0,
    lat: 135,
    lng: 28,
  }

  private init() {
    const lat = getLat();
    const lng = getLng();
    if (lat && lng) {
      this.listQuery.lat = lat;
      this.listQuery.lng = lng;
    } else {
      navigator.geolocation.getCurrentPosition((position) => {
        this.listQuery = {
          ...this.listQuery,
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        };
        setLat(position.coords.latitude);
        setLng(position.coords.longitude);
      });
    }
  }

  private getIndustryList() {
    getIndustryList().then((res) => {
      this.industryList = res.data;
      this.industryList.forEach((item: any, index: any) => {
        if (item.name === '일반상품') {
          this.industryList.splice(index, 1);
        }
        if (item.name === '레저') {
          this.industryList.splice(index, 1);
        }
        if (item.name === '체험') {
          item.name = '레저 / 체험';
        }
      });
      if (res.data.length > 0) {
        this.getFavoriteItemList();
      }
    });
  }

  private async getFavoriteItemList() {
    this.itemList = [];
    this.loading = true;
    await getFavoriteItemList(this.listQuery.industryIdx, this.listQuery).then((res) => {
      this.itemList = res.data.content;
      this.totalElements = res.data.totalElements;
      this.loading = false;
    });
  }

  private handleChangeIndustry() {
    this.listQuery.page = 0;
    this.getFavoriteItemList();
  }

  private handleDeleteItem(item: any) {
    this.$confirm('정말 저장된 항목에서 삭제하시겠습니까?', '저장 항목 삭제', {
      confirmButtonText: '확인',
      cancelButtonText: '취소',
      type: 'warning',
    }).then(() => {
      deleteFavoriteItem(item.idx).then(() => {
        this.$message.success('저장된 항목에서 삭제되었습니다.');
        this.getFavoriteItemList();
      });
    });
  }

  private handleClickItem(item: any) {
    if (item.industryIdx === 1) {
      this.$router.push({ name: 'StayDetail', params: { stayIdx: item.stayIdx } });
    } else if (item.industryIdx === 2) {
      this.$router.push({ name: 'LeisureView', params: { idx: item.storeInfoIdx } });
    } else if (item.industryIdx === 3) {
      this.$router.push({ name: 'LeisureView', params: { idx: item.storeInfoIdx } });
    } else if (item.industryIdx === 4) {
      this.$router.push({ name: 'RestaurantView', params: { idx: item.storeInfoIdx } });
    } else if (item.industryIdx === 5) {
      this.$router.push({ name: 'GoodsView' });
    } else if (item.industryIdx === 6) {
      this.$router.push({ name: 'PlaceView', params: { idx: item.storeInfoIdx } });
    }
  }

  private truncateName(name: string, maxLength: number) :string {
    if (name.length > maxLength) {
      return `${name.slice(0, maxLength)}...`;
    }
    return name;
  }

  private goBack() {
    this.$router.go(-1);
  }

  private distanceType(distance: number | string): number {
    const value = Number(distance);
    if (value > 1000) {
      return value / 1000;
    }
    return 0;
  }
}
